import { SocialAuthService } from '@abacritt/angularx-social-login';
import { AfterViewInit, Component, ElementRef, HostBinding, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterModule } from '@angular/router';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import { UserService } from '../services/user.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { marked } from 'marked';
import { DomSanitizer } from '@angular/platform-browser';
import { SafeHtmlPipe } from "../pipe/safe-html.pipe";
import { ClipboardModule, Clipboard } from '@angular/cdk/clipboard';
import { CLIPBOARD_OPTIONS, ClipboardButtonComponent, MarkdownModule, provideMarkdown } from 'ngx-markdown';
import { filter } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { TermsConditionsComponent } from '../terms-conditions/terms-conditions.component';
import { LoaderComponent } from '../common/loader/loader.component';

import { environment } from '../../environments/environment';

declare var Razorpay: any;

@Component({
  selector: 'app-dashboard',
  standalone: true,
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.css',
  imports: [CommonModule, NgbDropdownModule, FormsModule, ReactiveFormsModule,
    SafeHtmlPipe, ClipboardModule, MarkdownModule, RouterModule, TermsConditionsComponent, LoaderComponent],
  providers:[provideMarkdown()]
})

export class DashboardComponent implements OnInit {
  
  searchloader = false;
  isloading = false;
  disableChat: boolean = false
  loading: boolean = false
  username: any;
  useremail: any;
  query_string: any;
  reply: any;
  currentQuestion: any;
  counter: any;
  innerHeight: any;
  chatbotReply: any;
  sessionId: any;
  sessionResponse: any;
  questionCount: number;
  sessionList: any = [];
  shareLink: any
  isActive: number;
  whatsappNo: any
  selectedNoticeType: any = "it-notice" 
  copyButtonName: any = "Copy"
  filename: any = "Choose file"
  @ViewChild('myModal') myModal: ElementRef | undefined;
  @ViewChild('divscroll') divscroll: ElementRef | undefined;
  @ViewChild('elementRef') elementRef: ElementRef | any;
  @ViewChild('openTermsCondition') openTermsCondition!: ElementRef;
  @ViewChild('upload') upload!: ElementRef;
  @ViewChild('renewModel') renewModel!: ElementRef;
  @ViewChild('noticeType') noticeType!: ElementRef;
  @ViewChild('addOnQuestion') addOnQuestion!: ElementRef;
  @ViewChild('closeNoticeType') closeNoticeType!: ElementRef;
  @ViewChild('closeWhtType') closeWhtType!: ElementRef;
  @ViewChild('closeRenewButton') closeRenewButton!: ElementRef;
  @ViewChild('download') download!: ElementRef;
  


  todayHistory: any[] = []
  yesterdayHistory: any[] = []
  weekHistory: any[] = []
  monthHistory: any[] = []
  oldHistory: any[] = []

  selectedVersion:any = localStorage.getItem('selectedVersion') ?? '1.0';
  versions =[
    {"name": "1.0", "description": "Ask queries related to Income tax and GST"}, 
    {"name": "2.0", "description": "Detail answer with case Law"},
    {"name": "3.0", "description": "Automatic Notice Drafting (Trial Version)"},
    {"name": "4.0", "description": "Ask any thing about FEMA"}
  ]
  // Added by Goraksh
  userQuestion: any
  questionResponse: any
  currentSessionID: any
  subscriptionPlans: any[] = []
  theme: any = 'Dark Mode';
  totalQuestionCount: number;
  checkSubscription: boolean = false
  userdata = localStorage.getItem('user');
  user_subscription_plan: any = 1
  chatobject: any = []
  selected_file: any = [];
  user: any;
  planResponse: any;
  sub_id: any;
  amount: any;
  selectedPlan: any;
  userData: any

  constructor(
    private router: Router,
    private socialAuthService: SocialAuthService,
    private dataService: UserService,
    private sanitizer: DomSanitizer,
    private clipboard: Clipboard,
    private _Activatedroute: ActivatedRoute,
    private toastr: ToastrService
  ) {
    this.username = localStorage.getItem('name');
    this.useremail = localStorage.getItem('email');
    this.reply = '';
    this.counter = 1;
    this.chatbotReply = '';
    this.sessionId = '';
    this.questionCount = 0;
    this.totalQuestionCount = 0;
    this.getQuestionCount();
    this.getsessionlist();
    this.isActive = 0;
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: any) => {
      if(this._Activatedroute.snapshot.paramMap.get("sessionId")){
        this.getQustionBySessionId(this._Activatedroute.snapshot.paramMap.get("sessionId"))
        this.sessionId = this._Activatedroute.snapshot.paramMap.get("sessionId")
      }
    })
  }


  ngOnInit(): void {

    this.innerHeight = window.innerHeight - 160;
    this.getSubscription()

    let user = localStorage.getItem('user')
    if(user){
      let userDetail = JSON.parse(user)

      if(userDetail.user.role == 4){
        this.router.navigateByUrl('/vendor-dashborad')
      }
    }
    this.user = this.userdata!=null ? JSON.parse(this.userdata) : null

    this.getUser()
  }

  getUser(){
    let obj = {
      "user_id": localStorage.getItem("id")
    }

    this.dataService.getUser(obj)
    .subscribe((resp: any) => {
      if(resp.status == "success"){
        this.whatsappNo = resp.data.whatsapp_no
      }
    })
  }

  getplan(plan_cycle:any, plan_id: any){
    this.dataService.getSubscriptions(plan_cycle)
    .subscribe((planResponse: any) => {
      if(planResponse.results){
        this.planResponse = planResponse.results.filter((resp: any) => resp.id == plan_id)
        this.sub_id = this.planResponse[0].razorpay_subscription_plan_id
        this.amount = this.planResponse[0].amount
        this.selectedPlan = this.planResponse[0].id
      }
      console.log("--- :; ", this.user)
    }, (error) => {
    })
  }

  ngAfterViewInit(){
    let accept_terms_condition = localStorage.getItem('accept_terms_condition')
    if(!accept_terms_condition) this.openTermsCondition.nativeElement.click()
  }

  ngAfterViewChecked() {

    // if (this.elementRef!.nativeElement.querySelector('.copytext')) {
    //   this.elementRef.nativeElement.querySelector('.copytext').addEventListener('click', (e: any) => {
    //     console.log('click item');
    //     console.log(e.tostring());
    //   });
    // }

  }


  async getQuestionCount() {

    let subscription_version = localStorage.getItem("selectedVersion") ?? '1.0';
    this.dataService.questionCount({
      user_id: localStorage.getItem("id"),
      version: subscription_version,
    }).subscribe(res => {
       console.log('subscription_check',res);
      // console.log('subscription_version',subscription_version)
      if(res.subscription_status == 'renew'){
        this.getplan(res.billing_cycle, res.user_subscription_plan_id)

        if(res.user_subscription_plan_id!=1){
          this.renewModel.nativeElement.click()
        }
        this.checkSubscription = true
        this.user_subscription_plan = res.user_subscription_plan_id;

        console.log('user_subscription_plan',this.user_subscription_plan);
        console.log('checkSubscription',this.checkSubscription);
      }
      this.questionCount = res.question_count;
      this.totalQuestionCount = res.total_question_v1;
      if(subscription_version == '2.0'){
        this.totalQuestionCount = res.total_question_v2;
      } else if(subscription_version == '3.0'){
        this.totalQuestionCount = res.total_question_v3;
      }
      console.log('question_count',this.questionCount);
    });

  }

  async getsessionlist(getChatDetail: boolean = true) {

    this.todayHistory = []
    this.yesterdayHistory = []
    this.weekHistory = []
    this.monthHistory = []
    this.oldHistory = []

    this.dataService.sessionlist({
      user_id: localStorage.getItem("id"),
    }).subscribe(res => {
      let sortResult = res.results.sort((a: any, b: any) => { return b.id - a.id})

      if (sortResult.length && sortResult[0]['session_id']) {
        if (localStorage.getItem("login_now") == '1') {
          this.sessionId = ''
        } else {
          this.sessionId = this._Activatedroute.snapshot.paramMap.get("sessionId") ?? sortResult[0].session_id
        }

        setTimeout(() => { this.divscroll!.nativeElement.scrollTop = this.divscroll!.nativeElement.scrollHeight; }, 200);

       if(getChatDetail) this.getsessionResponse();

        sortResult.map((sessionList: any) => {
          let date = new Date(sessionList.created_at)
          let historyData = date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate()
          sessionList['created_at'] = historyData
        })

        let map: any = {};
        sortResult.forEach((val: any) => {
          let date = val.created_at.split(" ")[0];
          map[date] = map[date] || [];
          map[date].push(val)
        });

        this.sessionList = []
        sortResult.map((data: any) => {
          if(this.currentDate(data['created_at'])){
            this.todayHistory.push(data)
          }else if(this.yesterdatDate(data['created_at'])){
            this.yesterdayHistory.push(data)
          }else if(this.isDateInThisWeek(data['created_at'])){
            this.weekHistory.push(data)
          }else if(this.isDateInThisMonth(data['created_at'])){
            this.monthHistory.push(data)
          }else{
            this.oldHistory.push(data)
          }
        })
      }
    });
  }

  currentDate(date: any){
    const today = new Date()
    return new Date(date).getDate() == today.getDate() &&
      new Date(date).getMonth() == today.getMonth() &&
      new Date(date).getFullYear() == today.getFullYear()
  }

  isDateInThisWeek(date: any) {
    const todayObj = new Date();
    const todayDate = todayObj.getDate();
    const todayDay = todayObj.getDay();

    // get first date of week
    const firstDayOfWeek = new Date(todayObj.setDate(todayDate - todayDay));

    // get last date of week
    const lastDayOfWeek = new Date(firstDayOfWeek);
    lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 6);

    // if date is equal or within the first and last dates of the week
    return new Date(date) >= firstDayOfWeek && new Date(date) <= lastDayOfWeek;
  }

  isDateInThisMonth(date: any) {
    const today = new Date();
    const month = today.getMonth();
    const year = today.getFullYear();
    const day = today.getDate()

    if (new Date(date).getMonth() === month && new Date(date).getFullYear() === year && new Date(date).getDate() != day) {
      return true
    }

    return false
  }

  yesterdatDate(date: any){
    let dateToCheck = new Date(date);

    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    if (dateToCheck.toDateString() == yesterday.toDateString()){
      return true
    }

    return false
  }



  async getsessionResponse() {

    if(this.sessionId!='' && localStorage.getItem("id")!=''){
        console.log('first_time_call');
        this.dataService.sessionResponse({
          user_id: localStorage.getItem("id"),
          session_id: this.sessionId
        }).subscribe(res => {
          console.log("getsessionResponse------>>", res);
          this.currentSessionID = res.results ? res.results[0].session_id : ''
          // this.reply = res.results
          this.chatobject = res.results
          this.getQuestionCount();
          setTimeout(() => { this.divscroll!.nativeElement.scrollTop = this.divscroll!.nativeElement.scrollHeight; }, 200);
        });
      }else{
        console.log('call else Question count')
        this.getQuestionCount();
      }


  }

  getQustionBySessionId(sessionId: any) {

    this.sessionId = sessionId;

    this.reply = '';
    this.chatbotReply="";

    //this.query_string = '';
    this.getsessionResponse();
  }



  handleClick(event: MouseEvent) {
    console.log(event);
    const clickedElement = event!.target as HTMLElement;
    if (clickedElement.classList.contains('bi-clipboard')) {
      // Handle click logic here
      console.log('Element clicked:', clickedElement);
      console.log('Element clicked:', this.chatbotReply);
      //clickedElement.remove();
      clickedElement.innerHTML = "<i class='bi bi-check'></i>";
      this.clipboard.copy(this.chatbotReply);

    }

    if (clickedElement.classList.contains('bi-arrow-clockwise')) {
      // Handle click logic here
      console.log('Element clicked:', clickedElement);
      //clickedElement.remove();
      //clickedElement.innerHTML = "<i class='bi bi-check'></i>";
      //this.clipboard.copy(this.chatbotReply);
      //this.regenerate();

    }

    // For Like
    if (clickedElement.classList.contains('bi-hand-thumbs-up')) {
      let message_id = clickedElement.attributes.getNamedItem('message_id')?.value
      this.addFeedback(message_id, 1)
    }

    if (clickedElement.classList.contains('bi-hand-thumbs-down')) {
      let message_id = clickedElement.attributes.getNamedItem('message_id')?.value
      this.addFeedback(message_id, 0)
    }
  }

  openModal() {
    this.myModal!.nativeElement.style.display = "block";

  }

  closeModal() {
    this.myModal!.nativeElement.style.display = 'none';
  }

  logOut() {
    if (localStorage.getItem("logoutType") == 'social')
      this.socialAuthService.signOut();
    localStorage.removeItem("id");
    localStorage.removeItem("name");
    localStorage.removeItem("email");
    this.router.navigateByUrl('sign-in');
  };

  profile(){
    this.router.navigateByUrl('profile')
  }

  doChat(question: any = null){

    if(this.checkSubscription && this.user_subscription_plan!=1){
      this.renewModel.nativeElement.click()
      return 
    }

    this.disableChat = true
    this.isIconActive = false
    let selected_version = localStorage.getItem('selectedVersion') ?? '1.0';

    if(this.questionCount >= this.totalQuestionCount) {
      this.disableChat = false
      // this.openModal();
      this.addOnQuestion.nativeElement.style.display = "block"
      return;
    }

    this.query_string = question ?? this.query_string
    let search_Question = this.query_string
    this.isloading = true;
    this.searchloader = true

    if(search_Question){
      this.chatobject.push(
        {
          "question": search_Question,
          "response": '',
          "session_id": '',
          "show_loader": true
        }
      )

      setTimeout(() => { this.divscroll!.nativeElement.scrollTop = this.divscroll!.nativeElement.scrollHeight; }, 200);
      
      this.query_string = ''
      this.currentQuestion = search_Question;
      let last_element = this.chatobject[this.chatobject.length - 1];
      let responseText = ''
      this.dataService.doChat(search_Question, '', {},true).subscribe({
        next: (text) => {
          last_element['show_loader'] = false
          responseText += text
          last_element['response'] = responseText
          //console.log(this.chatbotReply)
          setTimeout(() => { this.divscroll!.nativeElement.scrollTop = this.divscroll!.nativeElement.scrollHeight; }, 200);
        },
        complete: () => {
          this.dataService.sessionResquest({
            user_id: localStorage.getItem("id"),
            question: this.currentQuestion,
            response: responseText,
            session_id: this.sessionId,
            version: localStorage.getItem('selectedVersion') ?? '1.0'
          }).subscribe(res => {
            console.log(res);
            this.sessionId = res.session_id;
            localStorage.setItem("login_now", "0");
            last_element['session_id'] = res.session_id
            this.getsessionlist(true);
            this.disableChat = false
          });
          this.disableChat = false
        },
        error: () => {
          this.disableChat = false
        }
      })
    }

  }

  // doChat2(question: any = null) {

  //   this.disableChat = true
  //   this.isIconActive = false
  //   let selected_version = localStorage.getItem('selectedVersion') ?? '1.0';

  //   if(this.questionCount > this.totalQuestionCount) {
  //     this.disableChat = false
  //     this.openModal();
  //     return;
  //   }

  //   // if (selected_version == '1.0' && this.questionCount >=30) {
  //   //   this.openModal();
  //   //   return;
  //   // }else if(selected_version == '2.0' && this.questionCount >=10){
  //   //   this.openModal();
  //   //   return;
  //   // }

  //   if (selected_version == '1.0' && this.questionCount > this.totalQuestionCount) {
  //     this.openModal();
  //     return;
  //   }else if(selected_version == '2.0' && this.questionCount >= this.totalQuestionCount){
  //     this.openModal();
  //     return;
  //   }

  //   this.searchloader = true
  //   this.query_string = question ?? this.query_string
  //   let search_Question = this.query_string

  //   if(!search_Question) {
  //     this.disableChat = false
  //     return
  //   }


  //   setTimeout(() => { this.divscroll!.nativeElement.scrollTop = this.divscroll!.nativeElement.scrollHeight; }, 200);

  //   this.chatbotReply += '<p class=""><img class="avatar avatar-24 bg-light rounded-circle text-white p-1 mt-3" src="assets/images/user-30.png">  <strong>' + this.username + '</strong></p>';
  //   this.chatbotReply += '<p class="ms-custom mb-4">' + search_Question + '</p>';

  //   this.chatbotReply += `<p class="mb-3">
  //                             <img class="avatar avatar-24 bg-light rounded-circle text-white"
  //                                 src="assets/images/taxbotgpt-30.png">
  //                             <strong> TaxBotGPT</strong>
  //                         </p>`
  //   // this.chatbotReply += `<span *ngIf="searchloader"
  //   //                         [ngClass]="darkTheme ? 'lightLoader' :'darkLoader'"
  //   //                         class="loader">
  //   //                       </span>`

  //   this.isloading = true;
  //   this.query_string = ''
  //   this.currentQuestion = search_Question;
  //   this.dataService.doChat(search_Question, '', {},true).subscribe({
  //     next: (text) => {
  //       this.searchloader = false
  //       this.chatbotReply += text
  //       //console.log(this.chatbotReply)
  //       setTimeout(() => { this.divscroll!.nativeElement.scrollTop = this.divscroll!.nativeElement.scrollHeight; }, 200);
  //     },
  //     complete: () => {
  //       // this.chatbotReply += `<div class="ms-custom text-white mt-2"><a title="Like" ><i class="bi bi-hand-thumbs-up" ></i></a> &nbsp;&nbsp; <a  title="Unlike" ><i class="bi bi-hand-thumbs-down" > </i></a> &nbsp;&nbsp; <a  title="Share" ><i class="bi bi-share" > </i></a> &nbsp;&nbsp; <a title="Copy" class="copytext"><i class="bi bi-clipboard" > </i></a> &nbsp;&nbsp;<a class="regenerate" title="Regenerate" ><i class="bi bi-arrow-clockwise" > </i></a></div>`
  //       // this.chatbotReply += '<p>&nbsp;</p> ';

  //         this.dataService.sessionResquest({
  //           user_id: localStorage.getItem("id"),
  //           question: this.currentQuestion,
  //           response: this.chatbotReply,
  //           session_id: this.sessionId,
  //           version: localStorage.getItem('selectedVersion') ?? '1.0'
  //         }).subscribe(res => {
  //           console.log(res);
  //           localStorage.setItem("login_now", "0");
  //           if(!this._Activatedroute.snapshot.paramMap.get("sessionId")){
  //             this.sessionId = res.session_id;
  //             this.router.navigateByUrl(`c/${res.session_id}`)
  //           }else{
  //             this.getQustionBySessionId(this._Activatedroute.snapshot.paramMap.get("sessionId"))
  //           }
  //           // if (localStorage.getItem("login_now") == '0') {
  //           //   this.getsessionlist();
  //           // }
  //           this.getsessionlist(true);
  //           this.disableChat = false
  //         });

  //     },
  //     error: () => {
  //       this.disableChat = false
  //     }
  //   })

  //   // .subscribe(res => {
  //   //   console.log(res);
  //   //   this.chatbotReply = res.reply;

  //   //   this.dataService.sessionResquest({
  //   //     user_id: localStorage.getItem("id"),
  //   //     question: this.currentQuestion,
  //   //     response: this.chatbotReply,
  //   //     session_id: this.sessionId
  //   //   }).subscribe(res => {
  //   //     console.log(res);
  //   //     this.sessionId = res.session_id;
  //   //     localStorage.setItem("login_now", "0");
  //   //     // if (localStorage.getItem("login_now") == '0') {
  //   //     //   this.getsessionlist();
  //   //     // }

  //   //   });

  //   //   this.reply += '<p class=""><img class="avatar avatar-24 bg-light rounded-circle text-white p-1" src="assets/images/user-30.png">  <strong>' + this.username + '</strong></p>';
  //   //   this.reply += '<p class="ms-custom mb-4">' + search_Question + '</p>';
  //   //   this.reply += '<p><img class="avatar avatar-24 bg-light rounded-circle text-white p-1" src="assets/images/taxbotgpt-30.png"> <strong>TaxBotGPT</strong></p> ';
  //   //   this.reply += '<div class="ms-custom">' + marked(this.chatbotReply) + '</div>';
  //   //   this.reply += '<div class="ms-custom text-white mt-2"><a title="Like" ><i class="bi bi-hand-thumbs-up" ></i></a> &nbsp;&nbsp; <a  title="Unlike" ><i class="bi bi-hand-thumbs-down" > </i></a> &nbsp;&nbsp; <a  title="Share" ><i class="bi bi-share" > </i></a> &nbsp;&nbsp; <a title="Copy" class="copytext"><i class="bi bi-clipboard" > </i></a> &nbsp;&nbsp;<a class="regenerate" title="Regenerate" ><i class="bi bi-arrow-clockwise" > </i></a></div>'
  //   //   this.reply += '<p>&nbsp;</p> ';
  //   //   this.isloading = false;
  //   //   this.query_string = '';
  //   //   setTimeout(() => { this.divscroll!.nativeElement.scrollTop = this.divscroll!.nativeElement.scrollHeight; }, 200);

  //   //   this.counter++;
  //   //   this.getsessionlist();
  //   // });
  //   this.getQuestionCount();
  // }

  regenerate(question:any) {
    this.query_string = question;
    this.doChat(question);
  }

  keyUpFunction($event: Event) {
    console.log("enter");
    this.doChat();
  }

  // Added by Goraksh
  newChat() {
    localStorage.setItem('login_now', "1")
    this.reply = ''
    this.chatbotReply = ''
    this.chatobject = []
    this.sessionId = ''
    this.router.navigateByUrl('/dashboard')
    //location.reload()
  }

  getSubscription() {
    this.subscriptionPlans = []
    // this.dataService.getPlans()
    //   .subscribe((planResponse: any) => {
    //     console.log('razorpay',planResponse)
    //    // this.subscriptionPlans = planResponse
    //   })
    this.dataService.getSubscriptions()
      .subscribe((planResponse: any) => {
        this.subscriptionPlans = planResponse.results
      })
  }

  subscribPlan(planData: any = null){
    this.loading = true
    let subscriptionData = {
      //plan_id: planData.id, // razorpay plan id
      plan_id: (planData && planData.razorpay_subscription_plan_id) ?? this.sub_id,
      total_count: 12
    }

    let plan_amount = (planData && planData.amount) ?  planData.amount * 100 : this.amount * 100 ;
    this.dataService.createSubscription(subscriptionData)
    .subscribe((subscribResponse: any) => {
      this.loading = false
      console.log('subscription response',subscribResponse);
      return new Promise((resolve, reject) => {
        const options = {
          key: environment.razorpay_key_id,
          amount: plan_amount,
          currency: 'INR', // Change to your currency
          name: 'TaxBotGPT',
          description: 'TaxBotGPT Subscription',
          image: 'assets/images/taxbotgpt-60.png', // URL of your company logo
          subscription_id: subscribResponse.id, // Order ID generated from server
          handler: (response: any) => {
            if(planData == null){
              this.saveSubscriptionRenewDetail(response, subscribResponse.id)
            }else{
              this.savePaymentDetail(response, subscribResponse.id)
            }
            resolve(response);
          },
         /* prefill: {
            email: 'tset92k@gmail.com',
            contact: '9604042331',
          },*/
          theme: {
            color: '#F37254'
          }
        };
        const razorpay = new Razorpay(options);
        razorpay.open();
      });
    })

    // let customerData = {
    //   name: 'Gaurav',
    //   email: 'tset1@gmail.com',
    //   contact: '9604042331',
    //   fail_existing: 0
    // }
    // this.dataService.createCustomer(customerData)
    // .subscribe((custResponse: any) => {
    //   if(custResponse['id']){
    //     let subscriptionData = {
    //       plan_id: planData.id,
    //       total_count: 8
    //     }
    //     this.dataService.createSubscription(subscriptionData)
    //     .subscribe((subscribResponse: any) => {
    //       console.log("--- >>> ", subscribResponse)
    //     })
    //   }
    // })
  }

  darkTheme = false;
  isChecked = false;

  @HostBinding('class') get themeMode() {
    this.darkTheme = localStorage.getItem('theme') === 'true'
    return this.darkTheme ? 'dark-theme' : 'light-theme';
  }

  toggleTheme() {
    this.darkTheme = !this.darkTheme;
    this.darkTheme ? this.theme = 'Light Mode' : this.theme = 'Dark Mode'
    localStorage.setItem('theme', this.darkTheme.toString())
  }

  savePaymentDetail(paymentResponse: any, subscription_id: any){
    if(paymentResponse && paymentResponse.razorpay_payment_id){
      this.dataService.getPaymentDetail(paymentResponse.razorpay_payment_id)
      .subscribe((paymentDetail: any) => {
        if(paymentDetail && (paymentDetail.status == "authorized" || paymentDetail.status == "captured")){
          let subscriptionObj = {
            user_id: localStorage.getItem("id"),
            plan_id: this.selectedPlan,
            payment_id: paymentResponse.razorpay_payment_id
          }
          this.dataService.saveSubscriptionDetail(subscriptionObj)
          .subscribe((response: any) => {
            if(response && response.status == "success"){
              this.toastr.success("Subscribed Successfully")
              // this.router.navigateByUrl('dashboard')
              window.location.href = '/dashboard'
            }
          })
        }
      })
    }
  }

  saveSubscriptionRenewDetail(paymentResponse: any, subscription_id: any){
    if(paymentResponse && paymentResponse.razorpay_payment_id){
      this.dataService.getPaymentDetail(paymentResponse.razorpay_payment_id)
      .subscribe((paymentDetail: any) => {
        if(paymentDetail && (paymentDetail.status == "authorized" || paymentDetail.status == "captured")){
          let subscriptionObj = {
            user_id: localStorage.getItem("id"),
            plan_id: this.selectedPlan,
            payment_id: paymentResponse.razorpay_payment_id
          }
          this.dataService.saveSubscriptionRenewDetail(subscriptionObj)
          .subscribe((response: any) => {
            if(response && response.status == "success"){
              this.toastr.success("Subscribed Successfully")
              this.closeRenewButton.nativeElement.click()
              window.location.reload()
              // this.router.navigateByUrl('dashboard')
              window.location.href = '/dashboard'
            }
          })
        }
      })
    }
  }


  addFeedback(message_id: any, feedback: any){
    let feedback_obj = {
      message_id: message_id,
      user_id: localStorage.getItem("id"),
      is_positive: feedback
    }

    this.dataService.postFeedback(feedback_obj)
    .subscribe((response: any) => {
      console.log(response)
      this.getQustionBySessionId(this.currentSessionID)
    })
  }

  isShow = false;
  iconClass = 'fas fa-bars';

  toggleClass() {
    this.isShow = !this.isShow;
    this.iconClass = this.isShow ? 'fas fa-times' : 'fas fa-bars';
  }

  isIconActive = false;

  onKeyDown(event: any) {
    if(event.target.value.length){
      this.isIconActive = true;
    }else{
      this.isIconActive = false
    }
  }

  closeAddOnModal(){
    this.addOnQuestion.nativeElement.style.display = "none"
  }


  versionDropdown(version:any){
    this.selectedVersion = version
    localStorage.setItem('selectedVersion',version)
    // get session count
    this.getQuestionCount();
    this.newChat()
  }

  uploadNote(event:any){

    this.noticeType.nativeElement.click()
    this.selected_file = []
    let files = event.target.files
    for (let i = 0; i < files.length; i++) {
      this.selected_file.push(files[i]);
      this.filename = files[i].name
    }
  }

  submitNotice(){
    // if(this.checkSubscription && this.user_subscription_plan!=1){
    //   this.renewModel.nativeElement.click()
    //   return 
    // }

    // if(this.questionCount >= this.totalQuestionCount) {
    //   this.disableChat = false
    //   this.closeNoticeType.nativeElement.click()
    //   this.addOnQuestion.nativeElement.style.display = "block"
    //   // this.openModal();
    //   return;
    // }

    this.toastr.info('Please wait, we are process your notice')
    this.loading = true
    this.closeNoticeType.nativeElement.click()

    let formData = new FormData()
    let filename = ''
    this.selected_file.forEach((file: any) => {
      formData.append('files', file, file.name);
      filename += ` ${file.name}`
    });
    // formData.append('files', this.selected_file)
    formData.append('notice_type', this.selectedNoticeType)

    this.dataService.uploadNotice(formData).subscribe(
      (response: any) => {
        if(response.success){
          this.dataService.getUploadNoticeResponse(response.file_id).subscribe(
            (chatResponse: any) => {
              this.chatobject.push(
                {
                  "question": filename,
                  "response": '',
                  "session_id": '',
                  "show_loader": true,
                  "notice_url": "",
                  "folder": response.file_id
                }
              )

              setTimeout(() => { this.divscroll!.nativeElement.scrollTop = this.divscroll!.nativeElement.scrollHeight; }, 200);
              
              let index = this.chatobject.length - 1
              let last_element = this.chatobject[index];
              let response_text = chatResponse.reply.replace('```', '')
              this.chatobject[index]['show_loader'] = false
              this.chatobject[index]['response'] = response_text
              this.chatobject[index]['notice_url'] = chatResponse.notice_url
              
              this.dataService.sessionResquest({
                user_id: localStorage.getItem("id"),
                question: this.chatobject[index]['question'],
                response: response_text,
                session_id: this.sessionId,
                version: localStorage.getItem('selectedVersion') ?? '1.0',
                notice_url: chatResponse.notice_url,
                summary: chatResponse.objections_summary[0],
                document_type: chatResponse.document_type,
                process_files: chatResponse.processed_files,
                folder_id: response.file_id
              }).subscribe(res => {
                console.log(res);
                this.sessionId = res.session_id;
                localStorage.setItem("login_now", "0");
                this.chatobject[index]['session_id'] = res.session_id
                this.getsessionlist(true);
                this.disableChat = false
                this.loading = false
              }, (error) => {
                this.loading = false
              });
            }
          )
        }

        this.upload.nativeElement.value = null
      }, (error) => {
        console.log(error)
        this.loading = false
      }
    )
  }

  removeFile(){
    this.selected_file = []
  }


  // Share Chat
  share(sessionID: any){
    this.shareLink = `https://taxbotgpt.ai/share/${sessionID}`
    this.copyButtonName = "Copy"
  }

  copyLink(){
    (document.getElementById('share-text-box') as HTMLInputElement).select();
    this.clipboard.copy(this.shareLink);
    this.copyButtonName = "Copied"
  }

  copy(response: any){
    this.clipboard.copy(response)
  }

  downloadReplay(folder: any){
    this.loading = true
    let obj = {
      folder_name: folder
    }
    this.dataService.post('replay/download', obj)
    .subscribe((response: any) => {
      if(response.status == 200){
        window.location.href = response.url
        this.loading = false
      }else{
        this.toastr.error("Oops..Something went wrong!")
        this.loading = false
      }
    }, (error: any) => {
      console.log(error)
      this.loading = false
      this.toastr.error("Oops..Something went wrong!")
    })
  }

  connectWithWhatsapp(){
    let obj = {
      "phone": this.whatsappNo,
      "user_id": localStorage.getItem("id")
    }
    this.loading = true
    this.dataService.connectWithWhatsapp(obj)
    .subscribe((response: any) => {
      this.loading = false
      console.log("--- :: ", response)
      if(response.status == "success"){
        this.toastr.success(response.message)
        this.closeWhtType.nativeElement.click()
      }else{
        this.toastr.error("Oops..Something went wrong!")
        this.closeWhtType.nativeElement.click()
      }
    }, (error: any) => {
      this.loading = false
      console.log("--- :: ", error)
      this.closeWhtType.nativeElement.click()
    })
  }
}


interface SessionData {
  session_id: string;
  user_id: string;
  question: string;
  response: string,
}
