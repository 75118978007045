import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-vendor-dashboard',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  templateUrl: './vendor-dashboard.component.html',
  styleUrl: './vendor-dashboard.component.css'
})
export class VendorDashboardComponent implements OnInit {

  email: any
  vendor_id: any
  @Input() referral_code: any
  @Output() sendInviteLink = new EventEmitter<any>()

  constructor(private router: Router){}

  ngOnInit(): void {
      let user  = localStorage.getItem('user')
      if(user){
        let user_json = JSON.parse(user)
        this.referral_code = user_json.referral_code
        this.vendor_id = user_json.user.id
      }
  }
  
  sendInvite(){
    let obj = {
      email: this.email,
      vendor_id: this.vendor_id,
      referral_code: this.referral_code
    }
    this.sendInviteLink.emit(obj)
  }



}
