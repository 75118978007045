<app-loader *ngIf="loading"></app-loader>
<!-- ==== offcanvas info start ==== -->
<div class="offcanvas-info">
    <div class="offcanvas-info__inner">
        <div class="offcanvas-info__content">

            <div class="card p-3 py-4">

                <div class="text-center">
                    <img    src="https://i.imgur.com/bDLhJiP.jpg" width="100" class="rounded-circle">
                </div>

                <div class="text-center mt-3">
                    <span class="bg-secondary p-1 px-4 rounded text-white">Free</span>
                    <h5 class="mt-2 mb-0 text-white">{{username}}</h5>

                    <div class="px-4 mt-4">
                    </div>

                    <div class="buttons mt-4">
                        <button (click)="logOut()" class="btn btn-outline-primary px-4"> <i class="fa fa-power-off"></i>
                            Log Out</button>
                        <button (click)="profile()" class="btn btn-primary px-4 ms-3">Profile</button>
                    </div>


                </div>
            </div>
        </div>


    </div>
    <button class="close-offcanvas-info" aria-label="close offcanvas info">
        <i class="bi bi-x-lg"></i>
    </button>
</div>
<div class="offcanvas-info-backdrop"></div>
<!-- ==== / offcanvas info end ==== -->

<div class="container-fluid">
    <div class="row">
        <div class="sidebar" [class.active]="isShow">
            <a style="text-decoration: none;" (click)="newChat()"
                class="new-chat chat p-2 rounded-pill d-flex justify-content-between">
                <div class="logo">
                    <img src="assets/images/taxbotgpt-60.png">
                    <span class="mx-2">New chat</span>
                </div>
                <div class="d-flex align-items-center ">
                    <i class="fa-regular fa-pen-to-square " style="color: #ffffff;"></i>
                </div>
            </a>
            <div id="scrollBarLeft" class="scrollbarmenu">
                <div>
                    <ng-container *ngIf="todayHistory.length">
                        <h3 class="chat-date pb-2 pt-3"> Today </h3>
                        <a *ngFor="let tdy of todayHistory" style="text-decoration: none;"
                            [style]="tdy.session_id == currentSessionID ? 'background: #d0d0d075': ''"
                            routerLink="/c/{{tdy.session_id}}"
                            class="new-chat chat p-2 rounded d-flex justify-content-between">
                            {{tdy.question}}
                        </a>
                        <!-- (click)="getQustionBySessionId(tdy.session_id)"  -->
                    </ng-container>
                    <ng-container *ngIf="yesterdayHistory.length">
                        <h3 class="chat-date pb-2 pt-3"> Yesterday </h3>
                        <a *ngFor="let ysdy of yesterdayHistory" style="text-decoration: none;"
                            [style]="ysdy.session_id == currentSessionID ? 'background: #d0d0d075': ''"
                            class="new-chat chat p-2 rounded d-flex justify-content-between"
                            routerLink="/c/{{ysdy.session_id}}">
                            {{ysdy.question}}
                        </a>
                    </ng-container>
                    <ng-container *ngIf="weekHistory.length">
                        <h3 class="chat-date pb-2 pt-3"> Week </h3>
                        <a *ngFor="let wk of weekHistory" style="text-decoration: none;"
                            [style]="wk.session_id == currentSessionID ? 'background: #d0d0d075': ''"
                            class="new-chat chat p-2 rounded d-flex justify-content-between"
                            routerLink="/c/{{wk.session_id}}">
                            {{wk.question}}
                        </a>
                    </ng-container>
                    <ng-container *ngIf="monthHistory.length">
                        <h3 class="chat-date pb-2 pt-3"> Month </h3>
                        <a *ngFor="let mnth of monthHistory" style="text-decoration: none;"
                            [style]="mnth.session_id == currentSessionID ? 'background: #d0d0d075': ''"
                            class="new-chat chat p-2 rounded d-flex justify-content-between"
                            routerLink="/c/{{mnth.session_id}}">
                            {{mnth.question}}
                        </a>
                    </ng-container>
                    <ng-container *ngIf="oldHistory.length">
                        <h3 class="chat-date pb-2 pt-3"> Old </h3>
                        <a *ngFor="let ol of oldHistory" style="text-decoration: none;"
                            [style]="ol.session_id == currentSessionID ? 'background: #d0d0d075': ''"
                            class="new-chat chat p-2 rounded d-flex justify-content-between"
                            routerLink="/c/{{ol.session_id}}"
                            >
                            {{ol.question}}
                        </a>
                    </ng-container>
                </div>
            </div>
            <div class="sidebar-footer">
                <a *ngIf="checkSubscription && user_subscription_plan!=1" (click)="subscribPlan(null)" style="text-decoration: none; display: block;"
                class="rounded-pill  p-2 mb-3 text-white user chat">
                <img src="assets/images/sub.png" width="60%" class="me-2">
                <span> Renew </span>
                </a>

                <a (click)="profile()" style="text-decoration: none; display: block;"
                class="rounded-pill  p-2 mb-3 text-white user chat">
                <img src="assets/images/star-icon.png" width="60%" class="me-2">
                <span> Upgrade  </span>
                </a>

                <a 
                    data-bs-toggle="modal" 
                    data-bs-target="#whatsapp"
                    style="text-decoration: none; display: block;"
                    class="rounded-pill  p-2 mb-3 text-white user chat">
                    <img src="assets/images/media.png" width="60%" class="me-2">
                    <span> Connect  </span>
                </a>

                <div class="dropdown profile-dropdown">
                    <a (click)="profile()" style="text-decoration: none; display: block; "
                        class="rounded-pill  p-2 mb-3 text-white user chat dropdown-toggle" id="dropdownMenuButton"
                        data-bs-toggle="dropdown" aria-expanded="false">
                        <img src="assets/images/user-30.png" width="60%">
                        <span class="mx-2">{{this.username}}</span>
                    </a>
                    <!-- <ul class="dropdown-menu dropdown-menu-dark dropdown-menu-bottom dropdown-menu-bottom-to-top"
                        aria-labelledby="dropdownMenuButton">
                        <li><a class="dropdown-item" (click)="logOut()"> 
                            <i class="fa fa-sign-out" aria-hidden="true"></i> Logout </a>
                        </li>
                    </ul> -->
                </div>
            </div>
        </div>
        <div class="col-lg-10 col-md-10 min-vh-100 offset-lg-2 right-sidebar">
            <button (click)="toggleClass()" class="d-lg-none togglebtn"><i [class]="iconClass"></i></button>
            <div class="row">
                <div class="col-12 d-flex justify-content-between">
                    <div class="dropdown">
                        <h4 class="text-white mt-3 upgradeplan dropdown-toggle" id="dropdownMenuButton2"
                            data-bs-toggle="dropdown" aria-expanded="false">TaxBotGPT
                            <span style="color:#B4B4B4;">{{ selectedVersion }}</span>
                        </h4>
                        <ul class="dropdown-menu shadow dropdown-menu-dark" aria-labelledby="dropdownMenuButton2">

                            <li 
                                *ngFor="let version of versions">
                                <a 
                                    [class.version_selected]="version.name == selectedVersion" 
                                    class="dropdown-item" 
                                    (click)="versionDropdown(version.name)"> 
                                    TaxBotGPT {{version.name}} <br> 
                                    <small> {{version.description}} </small>
                                </a></li>
                            <li><a href="/contact-us" class="dropdown-item"> TaxBotGPT Enterprise <br> <small> Customize API, Chatbot and White-labeling  Services. </small>
                                    <a class="btn btn-primary btn-block mt-2"> Upgrade to Enterprise </a>

                                </a></li>
                        </ul>
                    </div>

                    <button (click)="toggleTheme()" class="themechange-btn"> {{ theme }} </button>
                        <!-- <div class="d-none" style="cursor: pointer;"
                            class="mt-2 d-none chat rounded-pill p-1 open-offcanvas" ria-expanded="true">
                            <span>
                                <svg height="50" width="50" viewBox="0 0 24 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg" stroke="#ffffff">
                                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                    <g id="SVGRepo_iconCarrier">
                                        <circle cx="12" cy="9" r="3" stroke="#ffffff" stroke-width="1.5"></circle>
                                        <circle cx="12" cy="12" r="10" stroke="#ffffff" stroke-width="1.5"></circle>
                                        <path
                                            d="M17.9691 20C17.81 17.1085 16.9247 15 11.9999 15C7.07521 15 6.18991 17.1085 6.03076 20"
                                            stroke="#ffffff" stroke-width="1.5" stroke-linecap="round"></path>
                                    </g>
                                </svg>
                            </span>
                            <span>
                                <svg height="25" width="25" viewBox="0 0 24 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg" stroke="#ffffff">
                                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                    <g id="SVGRepo_iconCarrier">
                                        <path d="M7 10L12 15L17 10" stroke="#ffffff" stroke-width="1.5"
                                            stroke-linecap="round" stroke-linejoin="round"></path>
                                    </g>
                                </svg>
                            </span>
                        </div> -->
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div #divscroll id="scrollBarRight" style="width:100%; padding: 10% 17% 0 17%; overflow-y: scroll;"
                        [ngStyle]="{'height': innerHeight +'px', 'max-height': innerHeight +'px'}">

                        <div class="content markdown" [ngStyle]="!darkTheme ?  { 'color': '#111' } : { 'color': '#fff' }" #chatcontent>
                            <div
                                class="chat-content"
                                [ngStyle]="!darkTheme ?  { 'color': '#111' } : { 'color': '#fff' }">
                                    <ng-container class="mt-4" *ngFor="let chat of chatobject">
                                            <p class="">
                                                <img 
                                                    class="avatar avatar-24 chat-avatar p-1 bg-light rounded-circle p-1 mt-3" 
                                                    src="assets/images/user-30.png">  
                                                <strong> {{ username }}</strong></p>
                                            <p class="ms-custom mb-4">{{ chat.question}}</p>
                                            <p class="mb-3">
                                                <img class="avatar avatar-24 chat-avatar p-1 bg-light rounded-circle"
                                                    src="assets/images/taxbotgpt-30.png">
                                                <strong> TaxBotGPT </strong>
                                            </p>
                                            <markdown
                                                id="contentToConvert"
                                                ngPreserveWhitespaces
                                                [data]="chat.response.replace('```', '')">
                                            </markdown>
                                        <span *ngIf="chat.show_loader"
                                            [ngClass]="darkTheme ? 'lightLoader' :'darkLoader'"
                                            class="loader">
                                        </span>

                                        <div
                                            *ngIf="chat.session_id"
                                            class="ms-custom mt-2 mb-4"
                                            [ngClass]="darkTheme ? 'text-white': 'text-dark'">
                                            <a title="Like" >
                                                <i class="bi bi-hand-thumbs-up" ></i>
                                            </a> &nbsp;&nbsp;
                                            <a  title="Unlike" >
                                                <i class="bi bi-hand-thumbs-down" > </i>
                                            </a> &nbsp;&nbsp;
                                            <a
                                                data-bs-toggle="modal"
                                                data-bs-target="#share"
                                                class="share"
                                                [ngClass]="darkTheme ? 'text-white': 'text-dark'"
                                                (click)="share(chat.session_id)"
                                                title="Share" >
                                                <i class="bi bi-share" > </i>
                                            </a> &nbsp;&nbsp;
                                            <a
                                                (click)="copy(chat.response)"
                                                title="Copy"
                                                [ngClass]="darkTheme ? 'text-white': 'text-dark'"
                                                class="copy" >
                                                <i class="bi bi-clipboard" > </i>
                                            </a> &nbsp;&nbsp;
                                            <a  title="Regenerate" 
                                            (click)="regenerate(chat.question)">
                                                <i class="bi bi-arrow-clockwise" > </i>
                                            </a> &nbsp;&nbsp;
                                            <a
                                                (click)="downloadReplay(chat.folder_id)"
                                                *ngIf="selectedVersion == '3.0'"
                                                title="Download"
                                                [ngClass]="darkTheme ? 'text-white': 'text-dark'"
                                                class="copy" >
                                                <i class="bi bi-download" > </i>
                                            </a>
                                        </div>
                                    </ng-container>
                            </div>
                            <div class="text-center align-items-center" *ngIf="!chatobject.length">
                                <img class="avatar avatar-24 bg-light rounded-circle text-white"
                                    src="assets/images/taxbotgpt-60.png">
                                <h3 class="text-white mt-2 support-title">How can I help you today?</h3>
                                <p>During its learning phase, TaxBotGPT may sometimes provide imprecise answers as it
                                    aims to improve its accuracy</p>
                                
                                <h6 class="mt-4 mb-2" *ngIf="selectedVersion == '3.0'">
                                    TaxbotGPT 3.0: The Future of Notice Drafting, Cutting Your Workload by 70%
                                </h6>
                                <h6 *ngIf="selectedVersion == '3.0'">
                                    From Queries to Notices, TaxbotGPT 3.0 Delivers in Seconds
                                </h6>
                                <div 
                                    class="col-lg-12 suggestion-box m-auto mt-5 text-start"
                                    *ngIf="selectedVersion != '3.0'">
                                    <div class="row" *ngIf="selectedVersion != '4.0'">

                                        <div class="col-lg-5 mb-3">
                                            <div class="suggestion-col">
                                                <a (click)="doChat('What is e invoicing in gst')" class="position-relative">
                                                    What is e-Invoicing in GST
                                                    <!-- <i class="fa fa-angle-up" aria-hidden="true"></i> -->
                                                </a>
                                            </div>
                                        </div>
                                        <div class="col-lg-7 mb-3">
                                            <div class="suggestion-col">
                                                <a (click)="doChat('Section 80C income tax act')" class="position-relative">
                                                    Section 80C Income TAX ACT
                                                </a>
                                            </div>
                                        </div>

                                        <div class="col-lg-5 mb-3">
                                            <div class="suggestion-col">
                                                <a (click)="doChat('Whats section 44AD')" class="position-relative">Whats Section 44AD

                                                </a>
                                            </div>
                                        </div>
                                        <div class="col-lg-7 mb-3">
                                            <div class="suggestion-col">
                                                <a (click)="doChat('Reverse charge notification under gst')" class="position-relative">
                                                    Reverse Charge Notification under GST
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="selectedVersion == '4.0'">
                                        <div class="col-lg-6 mb-3">
                                            <div class="suggestion-col">
                                                <a (click)="doChat('Can foreign investors repatriate funds under FEMA?')" class="position-relative">
                                                    Can foreign investors repatriate funds under FEMA?
                                                    <!-- <i class="fa fa-angle-up" aria-hidden="true"></i> -->
                                                </a>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 mb-3">
                                            <div class="suggestion-col">
                                                <a (click)="doChat('What types of foreign investments are allowed under FEMA?')" class="position-relative">
                                                    What types of foreign investments are allowed under FEMA?
                                                </a>
                                            </div>
                                        </div>

                                        <div class="col-lg-6 mb-3">
                                            <div class="suggestion-col">
                                                <a (click)="doChat('Which sectors allow FDI?')" class="position-relative">
                                                Which sectors allow FDI?
                                                </a>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 mb-3">
                                            <div class="suggestion-col">
                                                <a (click)="doChat('What is FVCI under FEMA?')" class="position-relative">
                                                    What is FVCI under FEMA?
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row chat-box">
                
                <div class="col-12 search">
                    <div *ngIf="checkSubscription && user_subscription_plan!=1" class="m-auto">
                        <i *ngIf="checkSubscription" style="color: #8f0707;font-size: 16px;font-weight: 700;">Your subscription has expired.</i>
                        <button class="btn btn-sm m-auto renew-button" *ngIf="checkSubscription" (click)="subscribPlan(null)"> Renew </button>
                    </div>
                    <!-- <i style="color: #000;margin-bottom: 10px; padding: 15px 15px 0px 15px;font-weight: 700;" [ngStyle]="!darkTheme ?  { 'color': '#111' } : { 'color': '#fff' }">
                    {{selectedVersion == '3.0' ? 'No of Notice upload' : 'You have total asked questions are'}} : {{ questionCount }} </i>    
                    <i style="color: #000;margin-bottom: 10px; padding: 15px 15px 0px 15px;font-weight: 700;" [ngStyle]="!darkTheme ?  { 'color': '#111' } : { 'color': '#fff' }">
                    {{selectedVersion == '3.0' ? 'Total Notice  included in your plan' : 'Total questions included in your plan'}} : {{ totalQuestionCount }} </i> -->
                    <div 
                        *ngIf="selectedVersion != '3.0'"
                        class="search-box"
                        [class.darkmode]="darkTheme">
                        <input type="text"
                            [readOnly]="disableChat || checkSubscription"
                            [(ngModel)]="query_string"
                            [placeholder]="!checkSubscription ? 'Message TaxBotGPT...' : 'Your subscription has expired.'"
                            (keyup.enter)="keyUpFunction($event)"
                            (keyup)="onKeyDown($event)">

                          
                            <!-- <input type="file" [disabled]="checkSubscription" hidden multiple (change)="uploadNote($event)" #upload> -->
 
                            <!-- <small *ngIf="selected_file.length" class="fileupload-btn">
                                <span 
                                    class="comment-summary "
                                    > {{ selected_file[0].name }}
                                </span>
                                <i class="fa fa-times close cursor" (click)="removeFile()"></i>
                            </small>

                            <i class="fa fa-upload"
                                *ngIf="selectedVersion == '3.0'"
                                style="margin-right: 20px;"
                                [ngClass]="{ 'active': isIconActive }"
                                [style.background]="darkTheme ? '#565353': ''"
                                (click)="upload.click()">
                            </i> -->

                            <i class="fa fa-arrow-up"
                                [ngClass]="{ 'active': isIconActive }"
                                [style.background]="darkTheme ? '#565353': ''"
                                (click)="(disableChat || checkSubscription) ? undefined : doChat()">
                            </i>
                    </div>
                    <div class="col-lg-10 mx-auto" *ngIf="selectedVersion == '3.0'">
                        <!-- Upload image input-->
                        <div class="input-group mb-3 rounded-pill bg-grey shadow-sm" style="background: #e9ecef; border: 1px solid #bab4b4;">
                            <input id="upload" type="file" (change)="uploadNote($event)" class="form-control border-0">
                            <label id="upload-label" for="upload" class="font-weight-light text-muted" style="font-size: 1.1rem; font-weight: 600;">{{ filename }}</label>
                            <div class="input-group-append">
                                <label for="upload" class="btn btn-light m-0 rounded-pill px-4" style="background: #120f23; padding: 12px 40px;"> 
                                    <i class="fa fa-cloud-upload mr-2 text-muted" style="font-size: 1rem; margin-right: 10px;"></i>
                                    <small class="text-uppercase font-weight-bold text-muted" style="font-size: 1rem; font-weight: 600;">Choose file</small>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="text-center">
                        <span style="color:gray; font-size: 12px; margin-bottom: 10px;">
                            TaxBotGPT's insights are for education, not as formal tax advice.
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Modal -->
<div #myModal class="modal" tabindex="-1">
    <div class="modal-dialog modal-lg shadow-lg">
        <div class="modal-content bg-dark">
            <div class="modal-header">
                <h5 class="modal-title text-white" id="exampleModalLabel" style="text-align: center;">Upgrade your plan
                </h5>
                <button type="button" class="close" (click)="closeModal()" data-dismiss="modal" aria-label="Close">
                    <span class="text-white" aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body p-lg-3 upgradepopup">
                <section id="pricing" class="pricing-content section-padding">
                    <div class="container-fluid">					
                      <div class="section-title text-center">
                        <h2 [ngStyle]="!darkTheme ?  { 'color': '#111' } : { 'color': '#fff' }">Choose Your Best Plan</h2>
                        <p [ngStyle]="!darkTheme ?  { 'color': '#111' } : { 'color': '#fff' }">
                          AI Tax generator tools have emerged as powerful resources for unleashing creative possibilities and transforming.
                        </p>
                      </div>				
                      <div class="row">									
                        <div 
                        *ngFor="let plan of subscriptionPlans"
                        class="col-lg-3 col-sm-6 col-xs-12 wow fadeInUp" 
                        data-wow-duration="1s" 
                        data-wow-delay="0.1s" 
                        data-wow-offset="0" 
                        style="visibility: visible; animation-duration: 1s; animation-delay: 0.1s; animation-name: fadeInUp;padding: 0px;">
                        <div class="pricing_design">
                            <div class="single-pricing">
                            <div class="price-head">		
                                <h2 style="color: #6c757d;">{{ plan.plan_name }}</h2>
                                <h4 
                                style="font-weight: 800;"
                                class="text-center" 
                                *ngIf="plan.plan_type!='free' && plan.plan_type!='enterprise'">
                                ₹{{plan.price_per_month}} /month
                                </h4>
                            </div>
                            <div class="text-center" *ngIf="plan.plan_type!='free' && plan.plan_type!='enterprise'">
                                <button (click)="subscribPlan(plan)" class="price_btn">
                                    Upgrade to {{ plan.plan_name }}
                                </button>
                            </div>
                            <div class="text-center mt-5" *ngIf="plan.plan_type=='enterprise'">
                                <a href="/enterprise-enquiry"> <button class="price_btn"> Contact for Sales </button></a>
                            </div>
                            <ul>
                                <li  *ngFor="let feature of plan.features">
                                <i class="fa fa-check-circle f-18 mr-2"></i> {{feature}}
                                </li>
                            </ul>
                            </div>
                        </div>
                        </div>	  
                    </div>
                    </div>
                  </section>
                <!-- <div class="col-12">
                    <div class="row">
                        <ng-container *ngIf="subscriptionPlans">
                            <div *ngFor="let plan of subscriptionPlans" class="col-3 border-right">
                                <div class="plan-col mb-3">
                                    <div class="plan-col-header">
                                        <h4>{{ plan.plan_name }}</h4>
                                        <p class="text-secondary plan-price" *ngIf="plan.plan_type!='free' && plan.plan_type!='enterprise'">₹{{plan.price_per_month}}/month</p>
                                    </div>
                                    <div *ngIf="plan.plan_type!='free' && plan.plan_type!='enterprise'">
                                        <button (click)="subscribPlan(plan)" style="padding:7px;" class="btn btn-plan"
                                            [ngStyle]="{'background-color': '#10A37F' }">
                                            Upgrade to {{ plan.plan_name }}
                                        </button>
                                    </div>

                                    <div *ngIf="plan.plan_type=='enterprise'">
                                        <a href="/contact-us"> <button class="btn btn-plan"> Contact for Sales </button></a>
                                     </div>

                                    <div style="font-size: 14px;" class="text-white mt-3">
                                            <div *ngFor="let feature of plan.features" class="d-flex pt-3">
                                                <span>
                                                    <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round"
                                                        stroke-linejoin="round" class="mr-2 mt-1 h-4 w-4" height="1em" width="1em"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <polyline points="20 6 9 17 4 12"></polyline>
                                                    </svg>
                                                </span>
                                                <span class="ps-2">
                                                    {{feature}}
                                                </span>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div> -->
            </div>
            <div class="modal-footer d-flex justify-content-center">
                <a href="https://taxbotgpt.ai/contact-us" class="price_btn" role="button">Need more capabilities?
                    <span class="text-white"> Contact Taxbotai. </span></a>
            </div>

        </div>
    </div>
</div>
<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content bg-dark">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel"> Settings </h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">

                <div class="row">
                    <!-- Nav pills -->
                    <ul class="nav flex-column col-lg-2 p-0 m-0" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" data-bs-toggle="pill" href="#general">General</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-bs-toggle="pill" href="#speech"> Speech </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-bs-toggle="pill" href="#data"> Data Controls </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-bs-toggle="pill" href="#security"> Security </a>
                        </li>
                    </ul>
                    <!-- Tab panes -->
                    <div class="tab-content col-lg-10">
                        <div id="general" class="container tab-pane active"><br>

                            <ul class="list-group list-group-flush">
                                <li class="list-group-item">Theme </li>
                                <li class="list-group-item"> Always show code when using data analyst </li>
                                <li class="list-group-item d-lg-flex justify-content-between align-items-center">
                                    Archived chats <button class="btn-setting" data-bs-target="#exampleModalToggle2"
                                        data-bs-toggle="modal" data-bs-dismiss="modal"> Manage </button> </li>
                                <li class="list-group-item d-lg-flex justify-content-between align-items-center">
                                    Archive all chats <button class="btn-setting" data-bs-target="#exampleModalToggle2"
                                        data-bs-toggle="modal" data-bs-dismiss="modal"> Archive all </button> </li>
                                <li class="list-group-item d-lg-flex justify-content-between align-items-center"> Delete
                                    all chats <button class="btn-setting btn-delete"> Delete all </button> </li>
                            </ul>

                        </div>
                        <div id="speech" class="container tab-pane fade"><br>
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item">Voice </li>
                            </ul>
                        </div>
                        <div id="data" class="container tab-pane fade"><br>
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item">Improve the model for everyone </li>
                                <li class="list-group-item d-lg-flex justify-content-between align-items-center"> Shared
                                    links <button class="btn-setting" data-bs-target="#exampleModalToggle2"
                                        data-bs-toggle="modal" data-bs-dismiss="modal"> Manage </button> </li>
                                <li class="list-group-item d-lg-flex justify-content-between align-items-center"> Export
                                    data <button class="btn-setting" data-bs-target="#exampleModalToggle2"
                                        data-bs-toggle="modal" data-bs-dismiss="modal"> Archive all </button> </li>
                                <li class="list-group-item d-lg-flex justify-content-between align-items-center"> Delete
                                    account <button class="btn-setting btn-delete" data-bs-target="#exampleModalToggle2"
                                        data-bs-toggle="modal" data-bs-dismiss="modal"> Delete all </button> </li>
                            </ul>
                        </div>
                        <div id="security" class="container tab-pane fade"><br>

                            <ul class="list-group list-group-flush">
                                <li class="list-group-item d-lg-flex justify-content-between align-items-center">
                                    Multi-factor authentication
                                    Require an extra security challenge when logging in. If you are unable to pass this
                                    challenge, you will have the option to recover your account via email.
                                    <button class="btn-setting"> Enable </button>
                                </li>
                                <li class="list-group-item d-lg-flex justify-content-between align-items-center">
                                    Log out of all devices
                                    Log out of all active sessions across all devices, including your current session.
                                    It may take up to 30 minutes for other devices to be logged out.
                                    <button class="btn-setting btn-delete col-lg-2"> Logout all </button>
                                </li>
                            </ul>

                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalToggle2" aria-hidden="true" aria-labelledby="exampleModalToggleLabel2"
    tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content bg-dark">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalToggleLabel2">Archived Chats
                </h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">

                <table class="table table-dark table-hover">
                    <thead>
                        <tr>
                            <td scope="col"> Name </td>
                            <td scope="col"> Date created </td>
                            <td scope="col"> </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td scope="row"> CSS Tooltip Creation </td>
                            <td> March 28, 2023 </td>
                            <td> <a href="#" style="color: red;"> <i class="fa fa-trash" aria-hidden="true"></i> </a>
                            </td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalToggle3" aria-hidden="true" aria-labelledby="exampleModalToggleLabel3"
    tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-md">
        <div class="modal-content bg-dark">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalToggleLabel2"> Customize TaxBotGPT
                </h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="custom-inst">
                    <h5> Instructions </h5>
                    <p> What would you like ChatGPT to know about you to provide better responses? </p>
                    <textarea class="form-control mt-3" id="exampleFormControlTextarea1" rows="5"></textarea>
                    <p class="mt-4"> How would you like ChatGPT to respond? </p>
                    <textarea class="form-control mt-3" id="exampleFormControlTextarea1" rows="5"></textarea>
                </div>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-closes" data-bs-dismiss="modal">Close</button>
                <button type="button" class="btn btn-save">Save changes</button>
            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="share" aria-hidden="true" aria-labelledby="shareeModalToggleLabel"
    tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-md">
        <div class="modal-content bg-dark">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalToggleLabel2">Share public link to chat
                </h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-8">
                        <input
                            [value]="shareLink"
                            type="text"
                            id="share-text-box"
                            class="form-control share-text-box">
                    </div>
                    <div class="col-md-4">
                        <button
                            (click)="copyLink()"
                            type="button"
                            class="btn btn-default share-btn"
                            [ngStyle]="!darkTheme ?  { 'color': '#111' } : { 'color': '#fff' }">
                            {{ copyButtonName }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<button 
    style="display: none;"
    #openTermsCondition
    type="button" 
    class="btn btn-primary" 
    data-bs-toggle="modal" 
    data-bs-target="#temsCondition">
    Open modal
</button>

<button 
    style="display: none;"
    [disabled] = "checkSubscription"
    #renewModel
    type="button" 
    class="btn btn-primary" 
    data-bs-toggle="modal" 
    data-bs-target="#renewModel">
    Open modal
</button>

<div class="modal" id="renewModel" data-bs-backdrop='static'>
    <div class="modal-dialog modal-lg modal-dialog-scrollable">
      <div class="modal-content bg-dark">
        <div class="modal-header">
            <h5 class="modal-title modal-md">Renew</h5>
            <button type="button" #closeRenewButton class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <!-- Modal body -->
        <div class="modal-body">
            <h5> Your subscription has expired. </h5>
        </div>        
        <!-- Modal footer -->
        <div class="modal-footer">
            <button
                (click)="subscribPlan(null)"
                type="button"
                class="btn btn-default share-btn"
                [ngStyle]="!darkTheme ?  { 'color': '#111' } : { 'color': '#fff' }">
                Renew
            </button>
        </div>
      </div>
    </div>
  </div>

  <button 
    style="display: none;"
    #noticeType
    type="button" 
    class="btn btn-primary" 
    data-bs-toggle="modal" 
    data-bs-target="#noticeType">
    Open modal
</button>

  <div class="modal" id="noticeType" data-bs-backdrop='static'>
    <div class="modal-dialog vertical-align-center modal-dialog-scrollable">
      <div class="modal-content bg-dark modal-sm">
        <div class="modal-header">
            <h5 class="modal-title modal-md">Select Notice Type</h5>
            <button type="button" #closeNoticeType class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <!-- Modal body -->
        <div class="modal-body">
            <select 
                id="notice_type" 
                [(ngModel)]="selectedNoticeType" 
                class="form-control">
                <option value="it-notice">it-notice</option>
                <option value="gst-notice">gst-notice</option>
            </select>
        </div>        
        <!-- Modal footer -->
        <div class="modal-footer">
            <button
                (click)="submitNotice()"
                type="button"
                class="btn btn-default share-btn1"
                [ngStyle]="!darkTheme ?  { 'color': '#111' } : { 'color': '#fff' }">
                Submit
            </button>
        </div>
      </div>
    </div>
  </div>


  <div #addOnQuestion class="modal" id="addOnQuestion" data-bs-backdrop='static'>
    <div class="modal-dialog vertical-align-center modal-dialog-scrollable">
      <div class="modal-content bg-dark modal-md">
        <div class="modal-header">
            <h5 class="modal-title modal-md">TaxBotGPT - Limit Exceed</h5>
            <button type="button" (click)="closeAddOnModal()" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <!-- Modal body -->
        <div class="modal-body">
            <h5 class="text-center" [ngStyle]="!darkTheme ?  { 'color': '#111' } : { 'color': '#fff' }"> Thank you for your question! </h5>
            <p [ngStyle]="!darkTheme ?  { 'color': '#111' } : { 'color': '#fff' }">
                You've reached the limit 
                for the number of questions at this time.
            </p>
            <p [ngStyle]="!darkTheme ?  { 'color': '#111' } : { 'color': '#fff' }">
                Please feel free to ask more questions later, or when the limit resets. 
                We're here to help whenever you're ready!
            </p>
        </div>        
        <!-- Modal footer -->
        <div class="modal-footer">
            <a
                routerLink="/profile"
                type="button"
                class="btn btn-default share-btn1"
                [ngStyle]="!darkTheme ?  { 'color': '#111' } : { 'color': '#fff' }">
                Add more questions
            </a>
        </div>
      </div>
    </div>
  </div>

  <div class="modal" id="whatsapp" data-bs-backdrop='static'>
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content bg-dark modal-sm">
        <div class="modal-header">
            <h5 class="modal-title modal-md" style="font-size: 17px;">
                <img src="assets/images/media.png" alt="">
                Connect with whatsapp
            </h5>
            <button style="border: 1px solid #1b1919; border-radius: 50%; font-size: 10px;" type="button" #closeWhtType class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <!-- Modal body -->
        <div class="modal-body">
            <label class="mb-1">Enter Whatsapp Number</label>
            <input type="text" [(ngModel)]="whatsappNo" class="form-control" placeholder="Whatsapp Number">
        </div>        
        <!-- Modal footer -->
        <div class="modal-footer">
            <button
                [disabled]="!whatsappNo"
                (click)="connectWithWhatsapp()"
                type="button"
                style="padding: 6px 21px 7px 19px;"
                class="btn btn-default share-btn1"
                [ngStyle]="!darkTheme ?  { 'color': '#111' } : { 'color': '#fff' }">
                Connect
            </button>
        </div>
      </div>
    </div>
  </div>

<app-terms-conditions></app-terms-conditions>